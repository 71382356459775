export const tabs = [
  {
    title: 'Jour(s) ouvré(s)',
    slug: 'openDays',
  },
  {
    title: 'Jours féries',
    slug: 'dayoff',
  },
  {
    title: 'Type de congé',
    slug: 'leavesType',
  },
  { title: "Type d'absence", slug: 'absencesType' },
];
