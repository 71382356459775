import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquarePlus } from '@fortawesome/pro-light-svg-icons';
function AddCustom({ handleClick, title }) {
  return (
    <button
      type='button'
      onClick={handleClick}
      className='text-primary1 hover:opacity-80'
    >
      <FontAwesomeIcon size='lg' icon={faSquarePlus} className='mr-2' />
      <span className='text-base'>{title}</span>
    </button>
  );
}

export default AddCustom;
