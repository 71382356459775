import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ToastCustom from '@components/UI/ToastCustom';
import {
  createLeaveType,
  updateLeaveType,
  deleteLeaveType,
} from '../services/leaveTypes';
import { useSettingLeaves } from '../../../../context/SettingLeavesContext';

export const useLeaveTypes = ({ onModalClose, siteId = null } = {}) => {
  const { t } = useTranslation();
  const { setDefaultSiteParams, setLeaveSelected, setSitesParams } =
    useSettingLeaves();

  const handleApiError = (error, resetForm) => {
    console.error('API Error:', error);
    resetForm?.();
    ToastCustom.error(t('errorOnSave'));
  };

  const updateLeaveTypesState = useCallback(
    (updater) => {
      if (siteId) {
        setSitesParams((prev) => {
          const siteIndex = prev.findIndex((site) => site.id === siteId);

          if (siteIndex === -1) return prev;

          const updatedSites = [...prev];
          updatedSites[siteIndex] = {
            ...updatedSites[siteIndex],
            leave_types: updater(updatedSites[siteIndex].leave_types),
          };

          return updatedSites;
        });
      } else {
        setDefaultSiteParams((prev) => ({
          ...prev,
          leave_types: updater(prev.leave_types),
        }));
      }
    },
    [setSitesParams, setDefaultSiteParams, siteId]
  );

  const handleSuccessfullOperation = useCallback(
    (values, resetForm, successMessage) => {
      resetForm?.({ values });
      ToastCustom.validated(t(successMessage));
      setLeaveSelected(values);
    },
    [setLeaveSelected, t]
  );

  const updateType = useCallback(
    async (values, { resetForm }) => {
      try {
        const response = await updateLeaveType(values);
        updateLeaveTypesState((prevLeaveTypes) =>
          prevLeaveTypes.map((leave) =>
            leave.id === values.id ? response : leave
          )
        );
        handleSuccessfullOperation(response, resetForm, 'successUpdate');
      } catch (error) {
        handleApiError(error, resetForm);
      }
    },
    [updateLeaveTypesState, handleSuccessfullOperation, handleApiError]
  );

  const createType = useCallback(
    async (values, { resetForm }) => {
      try {
        const response = await createLeaveType(values, siteId);
        updateLeaveTypesState((prevLeaveTypes) => [
          ...prevLeaveTypes,
          response,
        ]);

        handleSuccessfullOperation(response, resetForm, 'successCreate');

        onModalClose?.();
      } catch (error) {
        handleApiError(error, resetForm);
      }
    },
    [
      updateLeaveTypesState,
      handleSuccessfullOperation,
      handleApiError,
      siteId,
      onModalClose,
    ]
  );

  const deleteType = async (id) => {
    try {
      await deleteLeaveType(id);

      updateLeaveTypesState((prev) => {
        const updatedLeaveTypes = prev.filter((lv) => lv.id !== id);
        const removedIndex = prev.findIndex((lv) => lv.id === id);

        if (removedIndex > 0) {
          const previousLeaveType = prev[removedIndex - 1];
          setLeaveSelected(previousLeaveType);
        } else if (updatedLeaveTypes.length > 0) {
          setLeaveSelected(updatedLeaveTypes[0]);
        } else {
          setLeaveSelected(null);
        }

        return updatedLeaveTypes;
      });

      ToastCustom.validated(t('successDelete'));
    } catch (error) {
      handleApiError(error);
    }
  };

  return {
    updateType,
    createType,
    deleteType,
  };
};
