import React from 'react';
import ParamsButton from './ui/Button';
import { useSettingLeaves } from '../../../context/SettingLeavesContext';
import { params } from './data/params';

function ParametersList() {
  const { paramsType, selectParams } = useSettingLeaves();

  return (
    <div className='flex gap-4'>
      {params.map(({ title, slug }) => (
        <ParamsButton
          key={slug}
          title={title}
          slug={slug}
          variant={paramsType === slug ? 'primary' : 'secondary'}
          handleClick={() => selectParams(slug)}
        />
      ))}
    </div>
  );
}

export default ParametersList;
