import React, { useEffect } from 'react';
import LeaveFormikProvider from '../../../lib/formik/LeaveFormikProvider';
import { useSettingLeaves } from '../../../../../../context/SettingLeavesContext';
import DaysOff from '.';
import { updateDayOffClient, updateDayOffSite } from '../../../services/dayoff';
import ToastCustom from '@components/UI/ToastCustom';
import { t } from 'i18next';
import dayjs from 'dayjs';

function DayoffContainer() {
  const { siteSelected, paramsType, setYearSelected, holidays, setHolidays } =
    useSettingLeaves();

  useEffect(() => {
    return () => setYearSelected(dayjs().year());
  }, [siteSelected.id]);

  const handleUpdate = {
    default: updateDayOffClient,
    site: updateDayOffSite,
  };

  const updateDayOff = handleUpdate[paramsType];

  const handleSubmit = async (values, { resetForm }) => {
    const ids = values.filter(({ isActive }) => isActive).map(({ id }) => id);

    try {
      await updateDayOff(siteSelected.id, ids);
      setHolidays(values);
      resetForm({ values });
      ToastCustom.validated(t('successUpdate'));
    } catch (error) {
      console.error(error);
      ToastCustom.error(t('errorOnSave'));
      resetForm();
    }
  };

  return (
    <LeaveFormikProvider
      handleSubmit={handleSubmit}
      initialValues={holidays}
      key={JSON.stringify(holidays)}
    >
      <DaysOff />
    </LeaveFormikProvider>
  );
}

export default DayoffContainer;
