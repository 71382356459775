import React, { useState } from 'react';
import Header from '../LeaveTypes/Header';
import AbsenceTypeList from './AbsenceTypeList';
import AbsenceTypeBoard from './AbsenceTypeBoard';
import ModalCustom from '../../../../../UI/ModalCustom';
import LeaveFormikProvider from '../../../lib/formik/LeaveFormikProvider';
import { useSettingLeaves } from '../../../../../../context/SettingLeavesContext';
import { useAbsenceTypes } from '../../../hooks/useAbsenceTypes';
import { defaultAbsenceType } from '../../../data/params';

const AbsenceTypesContainer = () => {
  const [search, setSearch] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const onModalClose = () => setIsOpen(false);
  const { absenceSelected, defaultSiteParams, siteSelected, paramsType } =
    useSettingLeaves();
  const { deleteAbsence, createAbsence, updateAbsence } = useAbsenceTypes({
    onModalClose,
    siteId: siteSelected?.id !== defaultSiteParams.id ? siteSelected?.id : null,
  });

  const containerSize = {
    default: 'h-[420px]',
    site: 'h-[370px]',
  };

  return (
    <div className='flex flex-col gap-4 h-full w-full'>
      <Header
        setSearch={setSearch}
        search={search}
        handleClick={() => {
          setIsOpen(true);
        }}
        title={"Créer un type d'absence"}
      />
      <LeaveFormikProvider
        initialValues={absenceSelected}
        handleSubmit={updateAbsence}
        key={JSON.stringify(absenceSelected)}
      >
        <div className={`flex gap-2  w-full ${containerSize[paramsType]} `}>
          <AbsenceTypeList search={search} deleteAbsence={deleteAbsence} />
          <AbsenceTypeBoard />
        </div>
      </LeaveFormikProvider>
      <ModalCustom
        title={"Créer un nouveau type d'absence"}
        isOpen={isOpen}
        onModalClose={onModalClose}
        customClass={'w-1/2 md:w-[900px]'}
        outsideClick={false}
      >
        <LeaveFormikProvider
          initialValues={defaultAbsenceType}
          handleSubmit={createAbsence}
          key={JSON.stringify(defaultAbsenceType)}
        >
          <div className='mt-4'>
            <AbsenceTypeBoard />
          </div>
        </LeaveFormikProvider>
      </ModalCustom>
    </div>
  );
};

export default AbsenceTypesContainer;
