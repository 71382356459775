import React from 'react';

import Header from './Header';
import DayOffList from './DayOffList';

function DaysOff() {
  return (
    <div className='flex flex-col gap-2'>
      <Header />
      <DayOffList />
    </div>
  );
}

export default DaysOff;
