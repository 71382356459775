import congesService from '@data/congesService';

export const updateLeaveType = async (data) => {
  try {
    const response = await congesService.put(
      `/v1/leave-types/${data.id}?withLeaveTypeSubFamilies=1`,
      data
    );
    return response.data.data;
  } catch (error) {
    console.error(error);
    throw Error(error);
  }
};

export const createLeaveType = async (data, siteId) => {
  try {
    const response = await congesService.post(`/v1/leave-types`, {
      ...data,
      ...(siteId && { site_id: siteId }),
    });
    return response.data.data;
  } catch (error) {
    console.error(error);
    throw Error(error);
  }
};

export const deleteLeaveType = async (leaveId) => {
  try {
    const response = await congesService.delete(`/v1/leave-types/${leaveId}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};
