import React from 'react';
import { useSettingLeaves } from '../../../../../context/SettingLeavesContext';
import SelectSite from './SelectSite';
import TabList from './TabList';

function SidebarSettings() {
  const { paramsType } = useSettingLeaves();
  return (
    <div className='w-full max-w-[250px]  border-solid border-r-2  rounded-md border-input-default p-4 flex flex-col gap-2'>
      {paramsType === 'default' && <TabList />}
      {paramsType === 'site' && <SelectSite />}
    </div>
  );
}

export default SidebarSettings;
