import React from 'react';

import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import InputCustom from '@src/components/UI/InputCustom';

import CustomInputNumber from './Leaves/ui/CustomInputNumber';
import CustomChexbox from './Leaves/ui/CustomChexbox';
import InfoTooltip from './Leaves/ui/InfoTooltip';
import LeaveWithPeriodRange from './Leaves/Components/sections/LeaveTypes/LeaveWithPeriodRange';

function AdvancedSettingsBis() {
  const { t } = useTranslation();
  const { setFieldValue, values } = useFormikContext();
  const {
    is_monthly,
    order_appearance,
    default_leave_value,
    increment_days_number,
    is_auto_increment_active,
    is_ignore_by_export,
    can_justify_later,
    is_attachment_required,
    is_half_day,
    can_exceed,
  } = values;

  return (
    <div className='w-full mt-2'>
      <h3>{t('advancedSettings')}</h3>

      <div className='flex flex-col gap-y-5 mt-2 w-full '>
        <div className='flex items-center justify-between relative'>
          <div className='flex items-center'>
            <CustomChexbox
              handleChange={(e) => {
                if (!is_monthly) return setFieldValue('order_appearance', 0);
                setFieldValue('order_appearance', e.target.checked ? 1 : 0);
              }}
              id={'order_appearanceToggler'}
              checked={is_monthly ? order_appearance > 0 : false}
              labelName={t('orderAppearance')}
              disabled={!is_monthly}
            />
            <InfoTooltip title={t('orderAppearanceTooltip')} />
          </div>
          <CustomInputNumber
            disabled={!is_monthly}
            handleChange={(e) => {
              setFieldValue('order_appearance', e.target.value);
            }}
            containerClass='absolute right-0'
            id={'order_appearance'}
            value={order_appearance}
          />
        </div>

        <div className='flex items-center justify-between relative'>
          <CustomChexbox
            checked={default_leave_value > 0}
            handleChange={() => {
              const defaultValue = default_leave_value > 0 ? 0 : 1;
              setFieldValue('default_leave_value', defaultValue);
            }}
            id={'max_consecutive_days'}
            labelName={t('maxConsecutiveDays')}
          />
          <CustomInputNumber
            handleChange={(e) => {
              setFieldValue('default_leave_value', e.target.value);
            }}
            containerClass='absolute right-0'
            id={'default_leave_value'}
            value={default_leave_value ?? 0}
          />
        </div>

        <div className='flex items-center'>
          <CustomChexbox
            disabled={!is_monthly}
            checked={is_monthly ? can_exceed : false}
            handleChange={(e) => {
              setFieldValue('can_exceed', e.target.checked);
            }}
            id={'can_exceed'}
            labelName={t('canExceed')}
          />
          <InfoTooltip title={t('canExceedTooltip')} />
        </div>

        <CustomChexbox
          checked={is_half_day}
          handleChange={(e) => setFieldValue('is_half_day', e.target.checked)}
          id={'is_half_day'}
          labelName={t('halfDayPossible')}
        />

        {/* TODO {is_monthly && !id && (
          <CustomChexbox
            checked={is_pay ?? false}
            handleChange={(e) => {
              setFieldValue('is_pay', e.target.checked);
              setSelectedLeaveType({
                ...selectedLeaveType,
                is_pay: e.target.checked,
              });
            }}
            id={'is_pay'}
            labelName={t('counterLastYear')}
            disabled={is_monthly && id}
          />
        )} */}

        <div className='flex gap-2'>
          <CustomChexbox
            checked={is_attachment_required}
            handleChange={(e) => {
              const value = e.target.checked;
              if (!value) setFieldValue('can_justify_later', value);
              setFieldValue('is_attachment_required', value);
            }}
            id={'is_attachment_required'}
            labelName={t('isAttachmentRequired')}
          />

          {is_attachment_required && (
            <div className='flex items-center'>
              <CustomChexbox
                checked={can_justify_later ?? false}
                handleChange={(e) => {
                  setFieldValue('can_justify_later', e.target.checked);
                }}
                id={'can_justify_later'}
                labelName={t('can_justify_later')}
              />
              <InfoTooltip title={t('canJustifyLaterTooltip')} />
            </div>
          )}
        </div>

        <div className='flex items-center'>
          <CustomChexbox
            checked={is_ignore_by_export}
            handleChange={(e) => {
              setFieldValue('is_ignore_by_export', e.target.checked);
            }}
            id={'is_ignore_by_export'}
            labelName={t('ignoreByExport')}
          />

          <InfoTooltip title={t('ignoreByExportTooltip')} />
        </div>

        <LeaveWithPeriodRange />

        <div className='flex items-center justify-between relative'>
          <CustomChexbox
            handleChange={(e) => {
              setFieldValue('is_auto_increment_active', e.target.checked);
            }}
            id={'personaliseCountLeave'}
            checked={is_auto_increment_active}
            labelName={t('personaliseCountLeave')}
          />

          {is_auto_increment_active && (
            <CustomInputNumber
              disabled={!is_monthly}
              handleChange={(e) => {
                setFieldValue(
                  'increment_days_number',
                  parseFloat(e.target.value)
                );
              }}
              containerClass='absolute right-0'
              id={'countPersonnalise'}
              value={increment_days_number}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default AdvancedSettingsBis;
