import React from 'react';
import LeaveFormikProvider from '../../../lib/formik/LeaveFormikProvider';
import OpenDays from '.';
import { useSettingLeaves } from '../../../../../../context/SettingLeavesContext';
import { handleUpdateOpenDays } from '../../../services/openDays';
import ToastCustom from '@components/UI/ToastCustom';
import { t } from 'i18next';
import {
  clientParamsFetch,
  siteParamsSearch,
} from '../../../services/data/Leaves';

const OpenDaysContainer = () => {
  const {
    siteSelected,
    setDefaultSiteParams,
    paramsType,
    setSitesParams,
    sitesParams,
  } = useSettingLeaves();

  const handleSubmit = async (days, { resetForm }) => {
    try {
      await handleUpdateOpenDays(paramsType, days, siteSelected.id);

      if (paramsType === 'default') {
        const response = await clientParamsFetch();
        setDefaultSiteParams({ ...response, days });
      } else if (paramsType === 'site') {
        const response = await siteParamsSearch('id', siteSelected.id);
        const oldDataIndex = sitesParams.findIndex(
          (site) => site.id === siteSelected.id
        );
        setSitesParams((prevSite) => {
          const data = [...prevSite];
          data[oldDataIndex] = { ...response, days };
          return data;
        });
      }
      resetForm({ values: days });
      ToastCustom.validated(t('successUpdate'));
    } catch (error) {
      ToastCustom.error(t('errorOnSave'));
      resetForm();
    }
  };

  return (
    <LeaveFormikProvider
      initialValues={siteSelected.days}
      handleSubmit={handleSubmit}
      key={JSON.stringify(siteSelected)}
    >
      <OpenDays />
    </LeaveFormikProvider>
  );
};

export default OpenDaysContainer;
