import React from 'react';

function SelectedName({ backgroundColor, title, titleClassName = '' }) {
  return (
    <div className='flex items-center gap-2 h-full'>
      <div className='h-1/2 w-[2px] my-auto' style={{ backgroundColor }} />
      <p className={`truncate ${titleClassName} `}>{title}</p>
    </div>
  );
}

export default SelectedName;
