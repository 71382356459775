export const weekDayIds = [
  {
    name: 'monday',
    id: 1,
  },
  {
    name: 'tuesday',
    id: 2,
  },
  {
    name: 'wednesday',
    id: 3,
  },
  {
    name: 'thursday',
    id: 4,
  },
  {
    name: 'friday',
    id: 5,
  },
  {
    name: 'saturday',
    id: 6,
  },
  {
    name: 'sunday',
    id: 7,
  },
];
