import React from 'react';
import MainLeaveTypeSettings from './MainLeaveTypeSettings';
import LeaveTypesBoardHeader from './LeaveTypesBoardHeader';
import AdvancedSettingsBis from '../../../../AdvancedSettingsBis';
import LeaveTypeSubFamilies from './LeaveTypeSubFamilies';

function LeaveTypesBoard() {
  return (
    <div
      className={`w-full h-full overflow-y-auto border-solid border-2 border-input-default rounded-md p-4 flex flex-col gap-3 vertical-scrollbar`}
    >
      <LeaveTypesBoardHeader />
      <MainLeaveTypeSettings />
      <AdvancedSettingsBis />
      <LeaveTypeSubFamilies />
    </div>
  );
}

export default LeaveTypesBoard;
