import React from 'react';
import PropTypes from 'prop-types';
import { colorConvert } from '../../../../../../utility/Utils';
import SelectedButton from '../../../ui/SelectedButton';
import SelectedName from '../../../ui/SelectedName';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { Tooltip } from '@mui/material';

const LeaveTypesItem = ({ data, handleClick, isSelected, handleDelete }) => {
  const onDelete = (e) => {
    if (!data.is_deletable) return;
    e.stopPropagation();
    e.preventDefault();
    handleDelete();
  };

  return (
    <SelectedButton
      className='flex items-center'
      handleClick={handleClick}
      isSelected={isSelected}
      type='button'
    >
      <SelectedName
        backgroundColor={colorConvert(data.color)}
        titleClassName={`max-w-[200px] ${
          !data.is_active ? 'line-through' : ''
        }`}
        title={data.name}
      />
      <Tooltip 
        title={!data.is_deletable && 'Ce type de congé ne peut pas être supprimé.'} 
        arrow
        placement='top'>
        <FontAwesomeIcon
          className={`ml-auto mr-2 ${data.is_deletable
            ? 'hover:text-primary1 hover:scale-125 transition-all'
            : ''
            }`}
          icon={data.is_deletable ? faTrashAlt : faLock}
          onClick={onDelete}
        />
      </Tooltip>
    </SelectedButton>
  );
};

LeaveTypesItem.propTypes = {
  data: PropTypes.shape({
    color: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    is_deletable: PropTypes.bool.isRequired,
    is_active: PropTypes.bool.isRequired,
  }).isRequired,
  handleClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  handleDelete: PropTypes.func,
};

LeaveTypesItem.defaultProps = {
  handleDelete: () => { },
};

export default LeaveTypesItem;
