import React from 'react';
import PropTypes from 'prop-types';
function SelectedButton({
  children,
  handleClick,
  isSelected,
  className = '',
  ...rest
}) {
  return (
    <button
      {...rest}
      onClick={handleClick}
      className={`px-1 h-10 rounded-md  ${
        isSelected && 'bg-[#505050] text-white'
      } ${className} `}
    >
      {children}
    </button>
  );
}

SelectedButton.propTypes = {
  children: PropTypes.node.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default SelectedButton;
