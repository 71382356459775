import React from 'react';
import { useSettingLeaves } from '../../../../../context/SettingLeavesContext';

function HeaderTitle() {
  const { paramsType, tab, siteSelected } = useSettingLeaves();

  const getTitle = (paramsType) => {
    if (paramsType === 'default') return tab.title;
    if (paramsType === 'site') {
      const siteSeletedName = siteSelected?.name || null;
      if (!siteSeletedName) return 'Aucun site selectionné';
      else return siteSeletedName;
    }
  };
  const title = getTitle(paramsType);
  return (
    <div className='w-full flex justify-between border-solid border-b-2 border-input-default pb-4'>
      <h2>{title}</h2>
    </div>
  );
}

export default HeaderTitle;
