import { useCallback } from 'react';
import { useSettingLeaves } from '../../../../context/SettingLeavesContext';
import {
  createLeaveType,
  deleteLeaveType,
  updateLeaveType,
} from '../services/leaveTypes';
import ToastCustom from '@components/UI/ToastCustom';
import { useTranslation } from 'react-i18next';

export const useAbsenceTypes = ({ onModalClose, siteId = null } = {}) => {
  const { t } = useTranslation();
  const {
    setAbsenceSelected,
    paramsType,
    setDefaultSiteParams,
    setSitesParams,
  } = useSettingLeaves();

  const updateDefaultSiteParams = useCallback(
    (updater) => {
      if (siteId) {
        setSitesParams((prev) => {
          const siteIndex = prev.findIndex((site) => site.id === siteId);

          if (siteIndex === -1) return prev;

          const updatedSites = [...prev];
          updatedSites[siteIndex] = {
            ...updatedSites[siteIndex],
            ...updater(updatedSites[siteIndex]),
          };

          return updatedSites;
        });
      } else if (paramsType === 'default') {
        setDefaultSiteParams((prev) => ({
          ...prev,
          ...updater(prev),
        }));
      }
    },
    [siteId, paramsType, setSitesParams, setDefaultSiteParams]
  );

  const handleApiError = useCallback(
    (error, resetForm) => {
      console.error('API Error:', error);
      resetForm?.();
      ToastCustom.error(t('errorOnSave'));
    },
    [t]
  );

  const updateAbsence = useCallback(
    async (values, { resetForm }) => {
      try {
        const updatedAbsence = await updateLeaveType(values);

        updateDefaultSiteParams((prev) => {
          const updatedAbsences = prev.absences.map((absence) =>
            absence.id === values.id ? updatedAbsence : absence
          );
          return { ...prev, absences: updatedAbsences };
        });

        setAbsenceSelected(updatedAbsence);
        resetForm({ values: updatedAbsence });
        ToastCustom.validated(t('successUpdate'));
      } catch (error) {
        handleApiError(error, resetForm);
      }
    },
    [setAbsenceSelected, updateDefaultSiteParams, t, handleApiError]
  );

  const createAbsence = useCallback(
    async (values, { resetForm }) => {
      try {
        const newAbsence = await createLeaveType(values, siteId);

        updateDefaultSiteParams((prev) => ({
          ...prev,
          absences: [...prev.absences, newAbsence],
        }));

        setAbsenceSelected(newAbsence);
        resetForm({ values: newAbsence });
        ToastCustom.validated(t('successCreate'));
        onModalClose?.();
      } catch (error) {
        handleApiError(error, resetForm);
      }
    },
    [
      setAbsenceSelected,
      updateDefaultSiteParams,
      t,
      handleApiError,
      onModalClose,
    ]
  );

  const deleteAbsence = useCallback(
    async (id) => {
      try {
        await deleteLeaveType(id);

        updateDefaultSiteParams((prev) => {
          const updatedAbsences = prev.absences.filter(
            (absence) => absence.id !== id
          );
          const removedIndex = prev.absences.findIndex(
            (absence) => absence.id === id
          );

          if (removedIndex > 0) {
            const previousAbsence = prev.absences[removedIndex - 1];
            setAbsenceSelected(previousAbsence);
          } else if (updatedAbsences.length > 0) {
            setAbsenceSelected(updatedAbsences[0]);
          } else {
            setAbsenceSelected(null);
          }

          return { ...prev, absences: updatedAbsences };
        });

        ToastCustom.validated(t('successDelete'));
      } catch (error) {
        handleApiError(error);
      }
    },
    [updateDefaultSiteParams, t, handleApiError, setAbsenceSelected]
  );

  return { createAbsence, updateAbsence, deleteAbsence };
};
