import { useFormikContext } from 'formik';
import React from 'react';
import InputCustom from '../../../../../UI/InputCustom';
import { t } from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { faSquarePlus } from '@fortawesome/pro-regular-svg-icons';

function LeaveTypeSubFamilies() {
  const { values, setFieldValue } = useFormikContext();

  if (values?.is_monthly) return null;
  const leaveTypeSubFamilies = values?.leave_type_sub_families ?? [];
  return (
    <div className='mt-2'>
      <h3>Sous type de congé</h3>
      <div>
        {leaveTypeSubFamilies?.length > 0 &&
          leaveTypeSubFamilies?.map((subFamilyType, index) => {
            return (
              <div
                key={index}
                className='flex gap-x-7 my-2 items-end justify-between'
              >
                <InputCustom
                  name={`leave_type_sub_families[${index}].name`}
                  label={t('label')}
                  id={`leave_type_sub_families[${index}].name`}
                  type='text'
                  value={subFamilyType?.name}
                  onChange={(e) => {
                    setFieldValue(
                      `leave_type_sub_families[${index}].name`,
                      e.target.value
                    );
                  }}
                />
                <InputCustom
                  name={`leave_type_sub_families[${index}].value`}
                  label={t('consecutiveDaysNumber')}
                  id={`leave_type_sub_families[${index}].value`}
                  type='number'
                  value={subFamilyType?.value}
                  onChange={(e) => {
                    setFieldValue(
                      `leave_type_sub_families[${index}].value`,
                      e.target.value
                    );
                  }}
                />

                <InputCustom
                  name={`leave_type_sub_families[${index}].absence_reason`}
                  id={`leave_type_sub_families[${index}].absence_reason`}
                  label={t('leaveCode')}
                  type='text'
                  value={subFamilyType?.absence_reason}
                  onChange={(e) => {
                    setFieldValue(
                      `leave_type_sub_families[${index}].absence_reason`,
                      e.target.value
                    );
                  }}
                />
                <button
                  type='button'
                  className='mb-2'
                  onClick={() => {
                    const newSubfamiliesList = leaveTypeSubFamilies.filter(
                      (e) => e.id !== subFamilyType.id
                    );
                    setFieldValue(
                      'leave_type_sub_families',
                      newSubfamiliesList
                    );
                  }}
                >
                  <FontAwesomeIcon
                    icon={faTrashCan}
                    size='lg'
                    className='text-gray-500'
                  />
                </button>
              </div>
            );
          })}
        <button
          type='button'
          onClick={() => {
            setFieldValue('leave_type_sub_families', [
              ...leaveTypeSubFamilies,
              {
                name: '',
                value: '',
                absence_reason: '',
              },
            ]);
          }}
          className='text-primary1 hover:opacity-80 mt-4'
        >
          <FontAwesomeIcon size='lg' icon={faSquarePlus} className='mr-2' />
          <span className='text-base'>
            Ajouter un nouveau sous-type de congés
          </span>
        </button>
      </div>
    </div>
  );
}

export default LeaveTypeSubFamilies;
