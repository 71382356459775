import React from 'react';
import OpenDaysContainer from '../sections/OpenDays/OpenDaysContainer';
import DayoffContainer from '../sections/DaysOff/DayoffContainer';
import LeaveTypesContainer from '../sections/LeaveTypes/LeaveTypesContainer';
import AbsenceTypesContainer from '../sections/AbsenceTypes/AbsenceTypesContainer';
import { useSettingLeaves } from '../../../../../context/SettingLeavesContext';

const SettingContainer = ({ slug }) => {
  switch (slug) {
    case 'openDays':
      return <OpenDaysContainer />;

    case 'dayoff':
      return <DayoffContainer />;

    case 'leavesType':
      return <LeaveTypesContainer />;

    case 'absencesType':
      return <AbsenceTypesContainer />;
    default:
      return <OpenDaysContainer />;
  }
};

function SettingSectionContainer() {
  const { paramsType, tab } = useSettingLeaves();

  const containerSize = {
    default: 'h-[420px]',
    site: 'h-[370px]',
  };

  return (
    <div className={`${containerSize[paramsType]}`}>
      <SettingContainer slug={tab.slug} />
    </div>
  );
}

export default SettingSectionContainer;
