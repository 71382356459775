import { useState, useEffect } from 'react';

import { useSettingLeaves } from '../../../../context/SettingLeavesContext';
import { handleDayOffFetch } from '../services/dayoff';

const useDayoffFetch = (clientId, year, countryAlpha = 'FR') => {
  const { paramsType } = useSettingLeaves();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await handleDayOffFetch(
          paramsType,
          clientId,
          year,
          countryAlpha
        );
        const data = response.data.map((dayoff) => {
          const { clients_exists, sites_exists } = dayoff;
          const isActive = clients_exists ?? sites_exists;
          return { ...dayoff, isActive };
        });
        setData(data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    if (clientId && year) {
      fetchData();
    }
  }, [clientId, year]);
  return { data, loading, error };
};

export default useDayoffFetch;
