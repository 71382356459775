import React, { useState, useCallback } from 'react';
import LeaveFormikProvider from '../../../lib/formik/LeaveFormikProvider';
import { useSettingLeaves } from '../../../../../../context/SettingLeavesContext';
import LeaveTypesBoard from './LeaveTypesBoard';
import LeaveTypesList from './LeaveTypesList';
import Header from './Header';
import { useLeaveTypes } from '../../../hooks/useLeaveTypes';
import useModal from '../../../hooks/useModal';
import CreateLeaveType from './CreateLeaveType';

function LeaveTypesContainer() {
  const { leaveSelected, siteSelected, defaultSiteParams } = useSettingLeaves();
  const [search, setSearch] = useState('');
  const { closeModal, isModalOpen, openModal } = useModal();

  const onModalClose = useCallback(() => setIsOpen(false), []);
  const { updateType, deleteType } = useLeaveTypes({
    onModalClose,
    siteId: siteSelected?.id !== defaultSiteParams.id ? siteSelected?.id : null,
  });

  return (
    <div className='flex flex-col gap-4 h-full w-full'>
      <Header
        setSearch={setSearch}
        search={search}
        handleClick={openModal}
        title={'Créer un type de congé'}
      />
      <div className='flex gap-2 h-full w-full'>
        <LeaveTypesList search={search} deleteType={deleteType} />
        <LeaveFormikProvider
          initialValues={leaveSelected}
          handleSubmit={updateType}
          key={leaveSelected?.id || 'empty'}
        >
          <LeaveTypesBoard />
        </LeaveFormikProvider>
      </div>
      <CreateLeaveType isOpen={isModalOpen} onModalClose={closeModal} />
    </div>
  );
}

export default LeaveTypesContainer;
