import React from 'react';
import AddCustom from '../../../ui/AddCustom';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import CustomSearch from '../../../ui/CustomSearch';

import PropTypes from 'prop-types';

function Header({ setSearch, search, handleClick, title }) {
  return (
    <div className='w-full h-10 flex justify-between items-center'>
      <CustomSearch
        id='searchSite'
        name='searchSite'
        value={search}
        className='cursor-text'
        containerClass='w-[243px]'
        icon={faSearch}
        placeholder={'Rechercher'}
        handleChange={(e) => {
          setSearch(e.target.value);
        }}
      />
      <div>
        <AddCustom handleClick={handleClick} title={title} />
      </div>
    </div>
  );
}

Header.propTypes = {
  setSearch: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default Header;
