import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useFormikContext } from 'formik';
import dayjs from 'dayjs';
import { capitalizeFirstLetter } from '../../../../../../utility/Utils';
import { useSettingLeaves } from '../../../../../../context/SettingLeavesContext';

function DayOffList() {
  const { values, setFieldValue } = useFormikContext();
  const { paramsType } = useSettingLeaves();

  const containerClass = {
    default: 'max-h-[460px]',
    site: 'max-h-[400px]',
  };

  if (!values) return <p>Chargement...</p>;
  return (
    <FormGroup
      className={`flex flex-col gap-x-2 flex-wrap ${containerClass[paramsType]} `}
    >
      {values
        .sort((a, b) => dayjs(a.date) - dayjs(b.date))
        .map((day, i) => {
          const { id, name, isActive, date } = day;

          const formatDate = dayjs(date).format('dddd DD MMMM');
          const title = `${name} (${capitalizeFirstLetter(formatDate)})`;

          const handleChange = (e) => {
            setFieldValue(`[${i}].isActive`, e.target.checked);
          };

          return (
            <div
              key={id}
              className='flex items-center justify-between w-[calc(50%-10px)] hover:bg-gray-100 px-2 pl-0 '
            >
              <FormControlLabel
                key={id}
                onChange={handleChange}
                control={<Checkbox sx={sx} />}
                label={title}
                checked={isActive}
              />
            </div>
          );
        })}
    </FormGroup>
  );
}

export default DayOffList;

const sx = {
  color: 'red',
  '&.Mui-checked': {
    color: 'red',
  },
};
