import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import LeaveTypesItem from './LeaveTypesItem';
import { removeAccents } from '../../../../../../utility/Utils';
import { useSettingLeaves } from '../../../../../../context/SettingLeavesContext';
import SwalCustom from '@helpers/SwalCustom';

const LeaveTypesList = ({ search, deleteType }) => {
  const { t } = useTranslation();
  const { siteSelected, leaveSelected, setLeaveSelected } = useSettingLeaves();

  const filterByName = useCallback(
    (leave) => {
      return removeAccents(leave.name.toLowerCase()).includes(
        removeAccents(search.toLowerCase())
      );
    },
    [search]
  );

  const filteredData = useMemo(
    () =>
      siteSelected.leave_types
        .filter(filterByName)
        .filter((leave) => leave.leave_code !== 'ABS'),
    [siteSelected.leave_types, filterByName]
  );

  const handleDeleteWithModal = (id) => {
    SwalCustom.delete(() => deleteType(id), {
      title: t('areYouSureContinue'),
      text: t('deleteAreForever'),
    });
  };

  return (
    <div className='w-[350px] h-full text-sm font-lato vertical-scrollbar overflow-y-auto border-solid border-2 border-input-default rounded-md p-2'>
      <h5 className='font-bold'>Les types de congés ({filteredData.length})</h5>
      <div className='flex flex-col gap-2 mt-1'>
        {filteredData.length < 1 && search !== '' && (
          <p className='text-center'>Aucun resultat </p>
        )}
        {filteredData.map((leave) => (
          <LeaveTypesItem
            key={leave.id}
            data={leave}
            handleClick={() => setLeaveSelected(leave)}
            isSelected={leave.id === leaveSelected?.id}
            handleDelete={() => handleDeleteWithModal(leave.id)}
          />
        ))}
      </div>
    </div>
  );
};

LeaveTypesList.propTypes = {
  search: PropTypes.string.isRequired,
};

export default LeaveTypesList;
