import React from 'react';
import ModalCustom from '../../../../../UI/ModalCustom';
import LeaveFormikProvider from '../../../lib/formik/LeaveFormikProvider';
import LeaveTypesBoard from './LeaveTypesBoard';
import { initialLeave } from '../../../data/params';
import { useSettingLeaves } from '../../../../../../context/SettingLeavesContext';
import { useLeaveTypes } from '../../../hooks/useLeaveTypes';

function CreateLeaveType({ isOpen, onModalClose }) {
  const { siteSelected, defaultSiteParams } = useSettingLeaves();
  const { createType } = useLeaveTypes({
    onModalClose,
    siteId: siteSelected?.id !== defaultSiteParams.id ? siteSelected?.id : null,
  });
  return (
    <ModalCustom
      title={'Créer un nouveau type de congé'}
      isOpen={isOpen}
      onModalClose={onModalClose}
      customClass={'w-1/2 md:w-[900px] max-h-[85vh] overflow-y-auto'}
      outsideClick={false}
    >
      <LeaveFormikProvider
        initialValues={initialLeave}
        handleSubmit={createType}
        key={JSON.stringify(initialLeave)}
      >
        <div className='mt-4'>
          <LeaveTypesBoard />
        </div>
      </LeaveFormikProvider>
    </ModalCustom>
  );
}

export default CreateLeaveType;
