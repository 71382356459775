import React, { useState } from 'react';
import ModalCustom from '../../../../UI/ModalCustom';
import { AsyncPaginateCustom } from '@components/UI/Inputs';
import {
  createSettingsSite,
  searchAllSites,
  siteParamsFetch,
  siteParamsSearch,
} from '../../services/data/Leaves';
import ToastCustom from '@components/UI/ToastCustom';
import { t } from 'i18next';
import {
  formatDays,
  getAbsencesTypes,
} from '../../services/formating/formating';
import { useSettingLeaves } from '../../../../../context/SettingLeavesContext';
import { handleDayOffFetch } from '../../services/dayoff';

function AddSite({ closeModal, isModalOpen }) {
  const [page, setPage] = useState(1);
  const [newSite, setNewSite] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { setSitesParams, yearSelected, setSiteSelected } = useSettingLeaves();

  const loadOptions = async (inputValue) => {
    const response = await searchAllSites(inputValue, page).catch(() => {
      return { options: [], hasMore: false };
    });
    if (!response) return response;

    const { current_page, last_page } = response.meta;
    const data = response.data;

    setPage(current_page < last_page ? current_page + 1 : 1);

    const hasMore = current_page < last_page;
    const options = data.map((res) => {
      return {
        ...res,
        name: res.name,
      };
    });

    return {
      options,
      hasMore,
    };
  };

  const createSite = async (site) => {
    const { id, days, leave_types } = site;

    try {
      setIsLoading(true);
      ToastCustom.validated(t('Création du site en cours'));
      await createSettingsSite(id);
      const newSite = await siteParamsFetch().then((res) =>
        res.find((site) => site.id === id)
      );
      const holidays = await handleDayOffFetch('site', id, yearSelected, 'FR');

      const formatedSite = {
        ...newSite,
        days: formatDays(days),
        absences: getAbsencesTypes(leave_types),
        holidays,
      };
      setSitesParams((prev) => {
        return [...prev, formatedSite];
      });
      setSiteSelected(formatedSite);

      ToastCustom.validated(t('successCreate'));
      closeModal();
    } catch (error) {
      console.error(error);
      ToastCustom.error(t('errorOnSave'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalCustom
      isOpen={isModalOpen}
      onConfirm={() => createSite(newSite)}
      onModalClose={closeModal}
      onCancel={closeModal}
      customClass='w-[500px] h-[500px]'
      confirmDisabled={newSite.length < 1 || isLoading}
    >
      <AsyncPaginateCustom
        containerClass={'h-full mt-2'}
        name={'add_site'}
        labelClassnames={'text-center'}
        label={'Ajouter un site'}
        placeholder={''}
        loadOptions={loadOptions}
        onChange={setNewSite}
        value={newSite}
        optionLabel={'name'}
        isMulti={false}
        optionValue={'id'}
      />
    </ModalCustom>
  );
}

export default AddSite;
