import React from 'react';
import { Formik, Form } from 'formik';
import SubmitButton from '../../Components/SubmitButton';

const LeaveFormikProvider = ({ children, initialValues, handleSubmit }) => {
  return (
    <Formik
      enableReinitialize={true}
      key={JSON.stringify(initialValues)}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      <Form className='w-full h-full'>
        <>
          <div className='absolute w-fit ml-auto right-0 top-0 p-4'>
            <SubmitButton />
          </div>
          {children}
        </>
      </Form>
    </Formik>
  );
};

export default LeaveFormikProvider;
