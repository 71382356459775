import React from 'react';
import { tabs } from '../../data/tabs';
import ParamsButton from '../../ui/Button';
import { useSettingLeaves } from '../../../../../context/SettingLeavesContext';

function TabList({ variantList = ['primary', 'inactive'] }) {
  const { tab, selectTab } = useSettingLeaves();
  const [active, notActive] = variantList;

  return tabs.map((currentTab) => {
    const { slug, title } = currentTab;
    const handleClick = () => selectTab(currentTab);

    return (
      <ParamsButton
        key={slug}
        handleClick={handleClick}
        slug={slug}
        title={title}
        variant={tab.slug === slug ? active : notActive}
        className='text-start px-2'
      />
    );
  });
}

export default TabList;
