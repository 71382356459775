import React from 'react';
import ParamsButton from '../ui/Button';
import { useFormikContext } from 'formik';

function SubmitButton() {
  const { dirty, isSubmitting } = useFormikContext();
  return (
    <ParamsButton
      type='submit'
      disabled={!dirty || isSubmitting}
      title={`${isSubmitting ? 'Chargement' : 'Enregistrer'}`}
      variant={dirty ? 'reversePrimary' : 'secondary'}
      className={`w-fit ${isSubmitting && 'cursor-progress'}`}
    />
  );
}

export default SubmitButton;
