import { useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomChexbox from '../../../ui/CustomChexbox';
import InfoTooltip from '../../../ui/InfoTooltip';
import InputCustom from '../../../../../UI/InputCustom';
import dayjs from 'dayjs';
function LeaveWithPeriodRange() {
  const { t } = useTranslation();
  const { setFieldValue, values  } = useFormikContext();

  const formatDate = (date) => dayjs(date).format('YYYY-MM-DD');
  const resetRangeValue = () => {
    setFieldValue('start_date', null);
    setFieldValue('end_date', null);
  };

  return (
    <>
      <div className='flex items-center'>
        <CustomChexbox
          checked={values?.start_date}
          handleChange={(e) => {
            const value = e.target.checked;
            if (!value) return resetRangeValue();

            const defaultDateValue = dayjs().format('YYYY-MM-DD');
            const nextDayValue = dayjs().add(7, 'day').format('YYYY-MM-DD');

            setFieldValue('start_date', defaultDateValue);
            setFieldValue('end_date', nextDayValue);
          }}
          id={'leaveWithRange'}
          labelName={t('leaveLimitedPeriod')}
        />

        <InfoTooltip title={t('isMonthlyTooltip')} />
      </div>

      {values?.start_date && (
        <div className='flex gap-x-6'>
          <InputCustom
            name='start_date'
            id='start_date'
            type='datePicker'
            dateValue={values?.start_date}
            label={t('startDate')}
            onChange={(value) => {
              setFieldValue('start_date', formatDate(value));
            }}
          />
          <InputCustom
            name='end_date'
            id='end_date'
            type='datePicker'
            labelClass={'text-end'}
            dateValue={values?.end_date}
            label={t('endDate')}
            onChange={(value) => {
              setFieldValue('end_date', formatDate(value));
            }}
          />
        </div>
      )}
    </>
  );
}

export default LeaveWithPeriodRange;
