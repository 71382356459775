import React from 'react';
import { SettingLeavesContextProvider } from '../../context/SettingLeavesContext';
import ParametersList from '../../components/Setting/Leaves/ParametersList';
import LeavesParamsContainer from '../../components/Setting/Leaves/LeavesParamsContainer';

function NewLeaves() {
  return (
    <SettingLeavesContextProvider>
      <div className='flex flex-col gap-4'>
        <ParametersList />
        <LeavesParamsContainer />
      </div>
    </SettingLeavesContextProvider>
  );
}

export default NewLeaves;
