import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { tabs } from '../components/Setting/Leaves/data/tabs';
import dayjs from 'dayjs';

const defaultValue = {
  paramsType: 'default',
  selectParams: () => {},
  tab: tabs[0],
  selectTab: () => {},

  siteSelected: null,
  selectSite: () => {},
  setSiteSelected: () => {},

  defaultSiteParams: {},
  setDefaultSiteParams: () => {},

  sitesParams: [],
  setSitesParams: () => {},

  leaveSelected: null,
  setLeaveSelected: () => {},

  absenceSelected: null,
  setAbsenceSelected: () => {},

  yearSelected: 2024,
  setYearSelected: () => {},

  holidays: [],
  setHolidays: () => {},
};

const SettingLeavesContext = createContext(defaultValue);

const SettingLeavesContextProvider = ({ children }) => {
  const [paramsType, setParamsType] = useState('default');
  const [tab, setTab] = useState(tabs[0]);

  const [defaultSiteParams, setDefaultSiteParams] = useState({
    leave_types: [],
    absences: [],
  });

  const [sitesParams, setSitesParams] = useState([]);

  const [siteSelected, setSiteSelected] = useState(null);
  const [leaveSelected, setLeaveSelected] = useState(null);
  const [absenceSelected, setAbsenceSelected] = useState(null);
  const [holidays, setHolidays] = useState([]);

  const [yearSelected, setYearSelected] = useState(dayjs().year());

  useEffect(() => {
    if (siteSelected && Array.isArray(siteSelected.leave_types)) {
      setLeaveSelected(siteSelected?.leave_types?.[0] || null);
      setAbsenceSelected(siteSelected?.absences?.[0] || null);
    }
  }, [siteSelected?.id, tab]);

  const selectParams = (params) => {
    selectTab(tabs[0]);
    setParamsType(params);
  };
  const selectTab = (tab) => setTab(tab);
  const selectSite = (site) => setSiteSelected(site);

  const value = useMemo(() => {
    return {
      paramsType,
      selectParams,
      tab,
      selectTab,
      siteSelected,
      selectSite,
      defaultSiteParams,
      setDefaultSiteParams,
      sitesParams,
      setSitesParams,
      leaveSelected,
      setLeaveSelected,
      yearSelected,
      setYearSelected,
      setSiteSelected,
      absenceSelected,
      setAbsenceSelected,
      holidays,
      setHolidays,
    };
  }, [
    paramsType,
    siteSelected,
    tab,
    sitesParams,
    defaultSiteParams,
    leaveSelected,
    yearSelected,
    absenceSelected,
    holidays,
  ]);

  return (
    <SettingLeavesContext.Provider value={value}>
      {children}
    </SettingLeavesContext.Provider>
  );
};

const useSettingLeaves = () => useContext(SettingLeavesContext);
export { SettingLeavesContextProvider, useSettingLeaves };
