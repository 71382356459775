import React from 'react';

import SelectYears from './SelectYears';

function Header() {
  return (
    <div className='w-full flex justify-between '>
      <SelectYears />
    </div>
  );
}

export default Header;
