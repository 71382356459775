import React from 'react';
import { Select, MenuItem } from '@mui/material';
import { useSettingLeaves } from '../../../../../../context/SettingLeavesContext';

function SelectYears() {
  const { yearSelected, setYearSelected } = useSettingLeaves();

  return (
    <Select
      sx={sx}
      value={yearSelected}
      displayEmpty
      onChange={(e) => {
        setYearSelected(e.target.value);
      }}
    >
      {years.map((year) => (
        <MenuItem key={year} value={year}>
          {year.toString()}
        </MenuItem>
      ))}
    </Select>
  );
}

export default SelectYears;

const years = [2024, 2025, 2026, 2027, 2028, 2029, 2030];

const sx = {
  height: '40px',
  backgroundColor: '#F2F2F2',
  color: 'black',
  borderRadius: '4px',
  '.MuiSelect-select': {
    padding: '8px 14px',
    display: 'flex',
    alignItems: 'center',
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
};
