import React, { useEffect } from 'react';
import { useSettingLeaves } from '../../../../../context/SettingLeavesContext';
import TabList from '../sidebar/TabList';
import SettingSectionContainer from './SettingSectionContainer';

function SettingSections() {
  const {
    defaultSiteParams,
    sitesParams,
    paramsType,
    siteSelected,
    selectSite,
  } = useSettingLeaves();

  useEffect(() => {
    if (paramsType === 'default') return selectSite(defaultSiteParams);
    if (paramsType === 'site') {
      const isLastSiteIsDefault =
        siteSelected?.id === defaultSiteParams.id && sitesParams.length >= 1;
      if (!sitesParams || sitesParams.length < 1) return selectSite(null);
      else if (isLastSiteIsDefault) selectSite(sitesParams[0]);
      else {
        const site = sitesParams.find((site) => site.id === siteSelected.id);
        selectSite(site);
      }
    }
  }, [paramsType, defaultSiteParams, sitesParams]);

  if (!siteSelected) return <NoSitePlaceholder />;

  return (
    <div className='p-2 flex flex-col  justify-between'>
      <div className='flex flex-col gap-4'>
        {paramsType === 'site' && (
          <div className='flex gap-2'>
            <TabList variantList={['primary', 'secondary']} />
          </div>
        )}

        <SettingSectionContainer />
      </div>
    </div>
  );
}

const NoSitePlaceholder = () => (
  <div className='text-center h-full flex justify-center items-center  gap-2 font-lato mt-2'>
    <h3 className='font-bold'>Vous n'avez aucun site</h3>
  </div>
);

export default SettingSections;
