import React, { useEffect, useState } from 'react';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import SiteList from './SiteList';
import { useSettingLeaves } from '../../../../../context/SettingLeavesContext';
import CustomSearch from '../../ui/CustomSearch';
import { removeAccents } from '../../../../../utility/Utils';
import { Plus } from 'lucide-react';
import useModal from '../../hooks/useModal';
import AddSite from './AddSite';
import { searchAllSites } from '../../services/data/Leaves';
import { Tooltip } from '@mui/material';

function SelectSite() {
  const [search, setSearch] = useState('');
  const { closeModal, isModalOpen, openModal } = useModal();
  const [isSiteAvailable, setIsSiteAvailable] = useState(false);
  const { sitesParams } = useSettingLeaves();

  const filteredSite = (site) =>
    removeAccents(site.name).includes(removeAccents(search));

  const filteredData = [...sitesParams].filter(filteredSite);

  const openIfAvailable = () => {
    if (isSiteAvailable) {
      openModal();
    };
  };

  const getSiteAvailable = async () => {
    const response = await searchAllSites();
    setIsSiteAvailable(response.data.length != 0);
  };

  useEffect(() => {
    getSiteAvailable();
  }, []);

  const plusStyle = isSiteAvailable ? 'cursor-pointer bg-primary1 hover:opacity-80' : 'bg-lightgrey-100';

  return (
    <div className='flex flex-col gap-2'>
      <div className='flex justify-between'>
        <h5 className='font-bold text-sm'>Les sites ({filteredData.length})</h5>
        <Tooltip
          title={!isSiteAvailable && "Il n'y a plus de site à ajouté"}
          placement='top'
        >
          <Plus
            onClick={openIfAvailable}
            color='white'
            size={20}
            className={`text-center text-xs h-6 w-6 rounded-[4px] ${plusStyle}`}
          />
        </Tooltip>
      </div>
      {isModalOpen && (
        <AddSite closeModal={closeModal} isModalOpen={isModalOpen} />
      )}
      <CustomSearch
        type='inputIcon'
        id='siteSearch'
        name='siteSearch'
        className='cursor-text'
        icon={faSearch}
        value={search}
        placeholder={'Rechercher'}
        handleChange={(e) => {
          setSearch(e.target.value);
        }}
      />
      {sitesParams.length < 1 ? (
        <NoSitePlaceholder />
      ) : (
        <SiteList search={search} sites={filteredData} />
      )}
    </div>
  );
}

const NoSitePlaceholder = () => (
  <div className='text-center flex flex-col gap-2 font-lato mt-2'>
    <h3 className='font-bold'>Vous n'avez aucun site</h3>
    <p className='text-xs'>
      Pour commencer à utiliser vos sites, nous vous invitons à créer votre
      premier site
    </p>
  </div>
);

export default SelectSite;
