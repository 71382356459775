import React from 'react';
import { useFormikContext } from 'formik';
import InputCustom from '../../../../../UI/InputCustom';
import { t } from 'i18next';

function OpenDays() {
  const { values, setFieldValue } = useFormikContext();

  if (!values) return null;
  return (
    <div className='flex gap-4 flex-col'>
      {Object.entries(values).map(([name, checked]) => {
        return (
          <InputCustom
            key={name}
            name={`${name}`}
            id={`${name}`}
            type='checkbox'
            value={checked}
            label={t(name)}
            labelClass='text-base'
            onChange={(e) => {
              setFieldValue(name, e.target.checked);
            }}
          />
        );
      })}
    </div>
  );
}

export default OpenDays;
