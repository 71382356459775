import React from 'react';
import LeaveTypesBoardHeader from '../LeaveTypes/LeaveTypesBoardHeader';
import MainAbsenceTypeSettings from './MainAbsenceTypeSettings';

function AbsenceTypeBoard() {
  return (
    <div
      className={`w-full h-full overflow-y-auto border-solid border-2 border-input-default rounded-md p-4 flex flex-col gap-3 vertical-scrollbar`}
    >
      <LeaveTypesBoardHeader />
      <MainAbsenceTypeSettings />
    </div>
  );
}

export default AbsenceTypeBoard;
