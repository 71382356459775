import React from 'react';

function ParamsButton({
  variant = 'inactive',
  title,
  handleClick = undefined,
  slug = undefined,
  className = '',
  disabled = false,

  ...props
}) {
  const color = {
    primary:
      'text-primary1 bg-primary2 active:shadow-md active:shadow-primary2',
    secondary: 'bg-input-default text-lightgrey-200',
    inactive: 'text-black hover:bg-gray-100',
    reversePrimary: 'bg-primary1 text-white',
    outlinePrimary: 'bg-white text-primary1 outline-1 outline outline-primary1',
    disabled: '',
  };

  return (
    <button
      name={slug}
      disabled={disabled}
      {...props}
      onClick={handleClick}
      className={`p-2 px-4 text-xs rounded-[4px] font-semibold hover:opacity-90  active:scale-95 transition duration-100  ${color[variant]} ${className}`}
    >
      {title}
    </button>
  );
}

export default ParamsButton;
