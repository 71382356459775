import React from 'react';
import ColorPicker from '../../../../../UI/ColorPicker';
import InputCustom from '../../../../../UI/InputCustom';
import { t } from 'i18next';
import InfoTooltip from '../../../ui/InfoTooltip';
import { useFormikContext } from 'formik';
import { colorConvert } from '../../../../../../utility/Utils';

function MainLeaveTypeSettings() {
  const { setFieldValue, values } = useFormikContext();

  return (
    <div className='grid grid-cols-2 gap-4 gap-x-8'>
      <div className=''>
        <span className='text-gray-500 text-sm leading-4'>{t('label') + '*'}</span>
        <InputCustom
          name='name'
          id='name'
          type='text'
          isRequired={true}
          value={values?.name}
          disabled={!values?.is_deletable}
          onChange={(e) => {
            setFieldValue('name', e.target.value);
          }}
        />
      </div>
      <div className=''>
        <span className='text-gray-500 text-sm leading-4'>
          {t('leaveCode') + '*'}
        </span>
        <InputCustom
          name='leave_code'
          id='leave_code'
          type='text'
          isRequired={true}
          disabled={!values?.is_deletable}
          value={values?.leave_code}
          onChange={(e) => {
            setFieldValue('leave_code', e.target.value);
          }}
        />
      </div>
      <div className=''>
        <span className='text-gray-500 text-sm leading-4'>{t('colors')} </span>
        <div className='flex items-center px-4 h-12 w-full rounded-md bg-lightgrey-50 pr-10 text-gray-600 placeholder-gray-600 focus:outline-none sm:text-sm'>
          <ColorPicker
            onChange={(e) => {
              setFieldValue('color', e.target.value);
            }}
            color={colorConvert(values?.color)}
            containerClasses='my-auto'
          />
        </div>
      </div>
      <div className=''>
        <span className='text-gray-500 text-sm leading-4'>
          {t('counters')} <InfoTooltip title={t('withCounterInfo')} />
        </span>
        {/* @TODO VOIR SUR LEAVE car supression des leave type sub famileis */}
        <InputCustom
          id='is_monthly'
          name='is_monthly'
          type='radio'
          containerClass='h-full my-auto mt-[12px]'
          value={values?.is_monthly}
          radioDirection='flex-row'
          onChange={(e) => {
            setFieldValue('is_monthly', e);
            if (!e) {
              setFieldValue('order_appearance', 0);
              setFieldValue('can_exceed', false);
            }
          }}
          firstLabel={'avec'}
          disabled={!values?.is_deletable}
          secondLabel={'sans'}
        />
      </div>
    </div>
  );
}

export default MainLeaveTypeSettings;
