import React, { useEffect } from 'react';
import HeaderTitle from './Components/container/HeaderTitle';
import SettingSections from './Components/container/SettingSections';
import SidebarSettings from './Components/sidebar/SidebarSettings';
import { useSettingLeaves } from '../../../context/SettingLeavesContext';
import { clientParamsFetch, siteParamsFetch } from './services/data/Leaves';
import { formatDays, getAbsencesTypes } from './services/formating/formating';
import useDayoffFetch from './hooks/useDayoffFetch';

function LeavesParamsContainer() {
  const {
    setDefaultSiteParams,
    setSitesParams,
    siteSelected,
    yearSelected,
    setHolidays,
  } = useSettingLeaves();

  const countryCode = siteSelected?.country_alpha ?? 'FR';
  const { data } = useDayoffFetch(siteSelected?.id, yearSelected, countryCode);

  useEffect(() => {
    clientParamsFetch()
      .then((res) => {
        const days = formatDays(res.days);
        const absences = getAbsencesTypes(res.leave_types);
        setDefaultSiteParams({ ...res, days, absences });
      })
      .catch((err) => console.error('err', err));
    siteParamsFetch()
      .then((res) => {
        const dataFormated = res.map((site) => {
          const days = formatDays(site.days);
          const absences = getAbsencesTypes(site.leave_types);
          return { ...site, days, absences };
        });
        setSitesParams(dataFormated);
      })
      .catch((err) => console.error('err', err));
  }, []);

  useEffect(() => {
    setHolidays(data);
  }, [data, siteSelected?.id, yearSelected]);

  return (
    <div className='w-full h-[570px] border-solid border-2 border-input-default rounded-md flex'>
      <SidebarSettings />
      <div className='p-4 flex flex-col relative divide-x h-full w-full'>
        <HeaderTitle />
        <SettingSections />
      </div>
    </div>
  );
}

export default LeavesParamsContainer;
