import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

function CustomSearch(props) {
  const {
    id,
    name,
    value,
    containerClass = '',
    label,
    placeholder,
    handleChange,
    inputClass = '',
    icon = null,
  } = props;

  return (
    <div className={containerClass}>
      <label
        htmlFor={id || name}
        className='block text-xs font-medium text-gray-700'
      >
        {label}
      </label>
      <div className='relative mt-1 rounded-md shadow-sm'>
        <div className='relative mt-1 rounded-md shadow-sm'>
          <input
            type='text'
            value={value}
            placeholder={placeholder}
            onChange={handleChange}
            className={`block w-full rounded-md border-none bg-lightgrey-50 pr-10 text-gray-600 placeholder-gray-60 focus:outline-none focus:ring-0 sm:text-sm ${inputClass} `}
            aria-describedby={name + '-error'}
          />
          {icon && (
            <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
              <FontAwesomeIcon
                icon={icon}
                className='h-5 w-5 text-lightgrey-500'
                aria-hidden='true'
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CustomSearch;
