import congesService from '@data/congesService';

const updateOpenDaysDefault = async (days) => {
  try {
    const response = await congesService.put('/v1/clients/open-days', days);
    return response;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

const updateOpenDaysSite = async (days, siteId) => {
  if (!siteId) throw new Error('Le champs siteId est manquant.');

  try {
    const response = await congesService.put(
      `/v1/sites/open-days/${siteId}`,
      days
    );
    return response;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const handleUpdateOpenDays = (site, days, siteId) => {
  switch (site) {
    case 'default':
      return updateOpenDaysDefault(days);
    case 'site':
      return updateOpenDaysSite(days, siteId);
    default:
      throw new Error('Erreur dans la selection du site');
  }
};
