import React, { useCallback } from 'react';
import { removeAccents } from '../../../../../../utility/Utils';
import { useSettingLeaves } from '../../../../../../context/SettingLeavesContext';
import LeaveTypesItem from '../LeaveTypes/LeaveTypesItem';
import SwalCustom from '@helpers/SwalCustom';
import { t } from 'i18next';

function AbsenceTypeList({ search, deleteAbsence }) {
  const { siteSelected, absenceSelected, setAbsenceSelected } =
    useSettingLeaves();

  const filterByName = (leave) => {
    return removeAccents(leave?.name).includes(removeAccents(search));
  };

  const filteredData = [...siteSelected.absences].filter(filterByName);

  const handleDeleteWithModal = (id) => {
    SwalCustom.delete(() => deleteAbsence(id), {
      title: t('areYouSureContinue'),
      text: t('deleteAreForever'),
    });
  };

  return (
    <div
      className={`w-[350px] h-full text-sm font-lato vertical-scrollbar overflow-y-auto border-solid border-2 border-input-default rounded-md p-2`}
    >
      <h5 className='font-bold'>Les types d'absence ({filteredData.length})</h5>
      <div className='flex flex-col gap-2 mt-1'>
        {filteredData.length < 1 && search !== '' && (
          <p className='text-center'>Aucun resultat </p>
        )}

        {filteredData?.map((leave) => {
          const isSelected = leave?.id === absenceSelected?.id;
          return (
            <LeaveTypesItem
              key={leave?.id}
              data={leave}
              handleClick={() => {
                setAbsenceSelected(leave);
              }}
              isSelected={isSelected}
              handleDelete={() => handleDeleteWithModal(leave.id)}
            />
          );
        })}
      </div>
    </div>
  );
}

export default AbsenceTypeList;
