import React from 'react';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { deleteSiteParams } from '../../services/data/Leaves';
import { useSettingLeaves } from '../../../../../context/SettingLeavesContext';
import SwalCustom from '@helpers/SwalCustom';
import { t } from 'i18next';

function SiteItem({ handleClick, id, name, isSelected }) {
  const { setSitesParams } = useSettingLeaves();

  const handleDeleteSite = (e) => {
    e.stopPropagation();
    SwalCustom.delete(() => deleteThenFetch(), {
      title: t('areYouSureContinue'),
      text: t('deleteAreForever'),
    });
  };

  const deleteThenFetch = () => {
    deleteSiteParams(id);
    setSitesParams((prev) => {
      return prev.filter((site) => site.id !== id);
    });
  };

  return (
    <button
      name={name}
      onClick={handleClick}
      className={`p-2 px-4 text-xs rounded-[4px] font-semibold hover:opacity-90 active:scale-95 transition duration-100 
        ${isSelected
          ? 'text-primary1 bg-primary2'
          : 'text-black hover:bg-gray-100'} 
          flex items-center gap-2 w-full`}
    >
      <p className={`truncate font-lato text-sm font-bold ${isSelected ? 'text-red-500' : 'black'}`}>
        {name}
      </p>
      <FontAwesomeIcon
        icon={faTrash}
        className='text-red-500 cursor-pointer ml-auto text-lg hover:text-red-700'
        onClick={handleDeleteSite}
      />
    </button>
  );
}

export default SiteItem;
