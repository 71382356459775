import React from 'react';
import SelectedName from '../../../ui/SelectedName';
import InputCustom from '../../../../../UI/InputCustom';
import { colorConvert } from '../../../../../../utility/Utils';
import { useFormikContext } from 'formik';

function LeaveTypesBoardHeader() {
  const { setFieldValue, values } = useFormikContext();
  if (!values) return null;

  return (
    <div className='flex items-center justify-between'>
      <div className='h-10 flex'>
        <SelectedName
          titleClassName={`${!values?.is_active && 'line-through'} font-medium`}
          title={values?.name}
          backgroundColor={colorConvert(values?.color)}
        />
      </div>
      <InputCustom
        name='is_active'
        id='is_active'
        type='switch'
        checked={values?.is_active}
        onChange={(e) => {
          setFieldValue('is_active', e.target.checked);
        }}
      />
    </div>
  );
}

export default LeaveTypesBoardHeader;
